









































































































import { Vue, Component } from "vue-property-decorator";
import { Routes } from "@/types/core";

@Component
export default class Header extends Vue {
  public $refs!: {
    event: Vue & {
      isActive: boolean;
    };
  };

  private readonly Routes: typeof Routes = Routes;

  protected get currentTab(): number | string {
    const eventsRoutes: string[] = [
      Routes.cycleEvents,
      Routes.events,
      Routes.eventID,
      Routes.cycleEventsID,
    ];

    const adminRoutes: string[] = [
      Routes["admin/roles/role"],
      Routes["admin"],
      Routes["admin/index"],
      Routes["admin/roles"],
      Routes["admin/library"],
      Routes["admin/archive"],
    ];

    const rules = [
      {
        links: eventsRoutes,
        returnValue: 2,
      },
      {
        links: adminRoutes,
        returnValue: "/admin",
      },
    ];

    const routeName: string = this.$route.name || "";

    return (
      rules.find((item) => item.links.includes(routeName))?.returnValue ||
      routeName
    );
  }

  public mounted(): void {
    window.onclick = () => {
      this.$nextTick(() => {
        const name = this.$route.name;
        if (name !== "Events" && name !== "cycleEvents") {
          this.$refs.event.isActive = false;
        } else {
          this.$refs.event.isActive = true;
        }
      });
    };
  }

  beforeDestroy(): void {
    window.onclick = null;
  }

  async logout(): Promise<void> {
    try {
      const { data } = await this.$store.dispatch("session/logout");
      if (data.logout) {
        await this.$router.push({ name: Routes["login"] });
      }
    } catch (err: unknown) {
      await this.$router.push({ name: Routes["login"] });
    }
  }
}
